<template>
  <v-container v-if="organization" id="location-body">
    <v-row>
      <v-col cols="12">
        <template>
          <h2>Orte verwalten</h2>
          <p>
            Hier können Sie Ihre Orte für physische Spendengeräte verwalten sowie die Direktlinks (QR-Code) zum Kollekte
            zählen am
            jeweiligen Ort abrufen. Den Direktlink zur Organisation auf kollekte.app / spende.app finden Sie links im
            Menü.
          </p>
          <p>
            Für den Einsatz des Digitalen Klingelbeutels im Gottesdienst vor Ort haben wir Ihnen ein Merkblatt
            zusammengestellt.
            Dieses können Sie hier herunterladen: <a :href="pdfLink"
                                                     download="2021-09-15_Gottesdienstablauf Digitaler KB.pdf">Gottesdienstablauf
            Digitaler KB.pdf</a>
          </p>
          <v-card
            class="nav-card"
            elevation="2"
            v-if="newLoc === true && $store.state.user.user.is_staff"
          >
            <h4>Neuen Ort anlegen</h4>
            <v-text-field
              label="Name"
              placeholder=""
              outlined
              dense
              v-model="newLocationName"
            ></v-text-field>
            <v-btn
              :disabled="this.newLocationName === ''"
              class="ml-1"
              elevation="2"
              outlined
              id="createLoc"
              @click="createLoc"
              :loading="creating"
            >Speichern
            </v-btn>

            <v-btn
              class="ml-3"
              elevation="2"
              plain
              id="cancelCreateLoc"
              @click="newLoc = false"
            >abbrechen
            </v-btn>
          </v-card>

          <v-btn block v-if="newLoc === false && $store.state.user.user.is_staff" @click="newLoc = true">
            +
          </v-btn>
          <v-expansion-panels
            multiple
            focusable
          >
            <v-expansion-panel
              v-for="location in filteredLocations"
              :key="location.id"
            >
              <v-expansion-panel-header>
                <div class="flex-column">
                  <div class="font-weight-bold mb-1">{{ location.name }}</div>
                  <div class="font-italic">{{ organization.name }}</div>
                </div>
              </v-expansion-panel-header>
              <count-collection-in-location-panel-content
                :location="location"
              ></count-collection-in-location-panel-content>
            </v-expansion-panel>

            <template v-for="subOrganisation in subOrganizations">
              <v-expansion-panel
                v-for="location in subOrganisation.locations"
                :key="location.id"
              >
                <v-expansion-panel-header>
                  <div class="flex-column">
                    <div class="font-weight-bold mb-1">{{ location.name }}</div>
                    <div class="font-italic">{{ subOrganisation.name }}</div>
                  </div>
                </v-expansion-panel-header>
                <count-collection-in-location-panel-content
                  :location="location"
                ></count-collection-in-location-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>

        </template>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import CountCollectionInLocationPanelContent from "@/components/management/CountCollectionInLocationPanelContent.vue"
import { ADD_LOCATION, FETCH_LOCATIONS, FETCH_ORGANIZATIONS } from '@/store/action-types'
import { mapGetters, mapState } from 'vuex'
import { sortAlphaNumeric } from "../../lib/sort-utils"

export default {
  name: 'org-locations',
  components: {
    CountCollectionInLocationPanelContent
  },
  data () {
    return {
      activeLocation: null,
      newLocationName: '',
      creating: false,
      error: null,
      newLoc: false,
      pdfLink: require("@/assets/downloads/2021-09-15_Gottesdienstablauf Digitaler KB.pdf"),
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapState('location', ['locations']),
    ...mapGetters('organization', {
      organization: 'currentOrganization',
      allOrganizations: 'allOrganizations'
    }),
    filteredLocations () {
      if (!this.locations) {
        return []
      }
      const locations = this.locations.filter(loc => loc.organization.includes(this.organization.id))

      return sortAlphaNumeric(locations, 'name')
    },
    subOrganizations () {
      const subOrganizations = Object.values(this.allOrganizations)
        .filter(org => {
          if (!org.parent) {
            return false
          }
          return org.parent.includes(this.organization.id)
        })
        .map(org => {
          const locations = this.locations.filter(loc => loc.organization.includes(org.id))
          org.locations = sortAlphaNumeric(locations, 'name')
          return org
        })

      return sortAlphaNumeric(subOrganizations, 'name')
    },
  },
  methods: {
    async createLoc () {
      this.creating = true
      try {
        await this.$store.dispatch('location/' + ADD_LOCATION, {
          name: this.newLocationName,
          organization: this.organization.url
        })
      } finally {
        this.creating = false
        this.newLoc = false
      }
    },
  },
  mounted () {
    this.$nextTick(() => {
      Promise.allSettled([
        this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
        this.$store.dispatch('location/' + FETCH_LOCATIONS)
      ])
    })
  }
}
</script>
<style lang="stylus">
</style>
