export default {
  methods: {
    hasPermission: (user, currentOrg) => {
      const permissions = user.permissions
        .filter(permission => permission.organization === currentOrg?.url)
        .map(permission => permission.role === 'hier_user_admin' || permission.role === 'org_user_admin')
      const hasPermission = permissions.find(permission => permission === true)
      // eslint-disable-next-line camelcase
      return !!(hasPermission || user?.is_superuser)
    }
  }
}
