<template>
  <v-container id="org-users" v-if="checkPermission">
    <template>
      <h2>Benutzer</h2>
        <p>
          Bitte vergeben Sie Benutzernamen nach dem folgenden Schema:
          <br>
          Name: Tobi Testhase Benutzername: t.testhase E-Mail-Adresse: t.testhase@digitalwolff.de
        </p>
        <p>
          oder für Institutionen
          <br>
          Name: "-" Benutzername: digitalwolff-berlin E-Mail-Adresse: info@digitalwolff.de
        </p>
      <p>
        Bitte beachten Sie, dass Groß- und Kleinschreibung relevant ist und alle Felder Pflichtfelder sind.
      </p>
    </template>
    <v-spacer></v-spacer>
    <UserAdmin :specificOrganization="organization.url"/>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import UserAdmin from "@/views/admin/UserAdmin"
import PermissionToViewUsersMixin from "@/mixins/permission-to-view-users.mixin.js"

export default {
  name: "OrgUsers",
  mixins: [
    PermissionToViewUsersMixin
  ],
  components: {
    UserAdmin
  },
  data () {
    return {}
  },

  computed: {
    ...mapGetters('organization', { organization: 'currentOrganization' }),
    ...mapState('user', ['user']),
    checkPermission () {
      return this.hasPermission(this.user, this.organization)
    }
  }
}
</script>

<style scoped>

</style>
