<template>
  <v-text-field
    v-model="inputValue"
    :error="error"
    v-bind="$attrs"
    :append-icon="mdiCurrencyEur"
    :hint="hint"
    @input="parse()"
    @blur="parse(true)"
  />
</template>

<script>
import toCurrencyString from '@/filters/currency'
import { mdiCurrencyEur } from '@mdi/js'

export default {
  name: "CurrencyInput",
  props: {
    value: {
      type: Number,
      required: true,
    },
    showInterpretationHint: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    internalValue: 0,
    inputValue: '',
    error: false,
    mdiCurrencyEur,
  }),
  computed: {
    hint () {
      if (this.error) return 'Ungültiger Betrag'
      if (this.showInterpretationHint) {
        const valStr = toCurrencyString(this.internalValue, '')
        if (valStr !== this.inputValue) return '= ' + valStr + '€'
      }
      return ''
    },
  },
  watch: {
    value (newVal) {
      if (newVal !== this.internalValue) {
        this.internalValue = newVal
        this.inputValue = toCurrencyString(newVal, '')
      }
    },
    internalValue (newVal) {
      if (newVal !== this.value) {
        this.$emit('input', newVal)
      }
    },
  },
  methods: {
    parse (sanitize) {
      const hasInputContent = this.inputValue.trim().length > 0
      let val = 0
      if (hasInputContent) {
        const s = this.inputValue.replace('.', '').replace(',', '.')
        val = Math.round(parseFloat(s) * 100)
      }
      if (isNaN(val)) {
        this.internalValue = 0
        this.error = true
      } else {
        this.internalValue = val
        this.error = false
        if (sanitize) {
          this.inputValue = hasInputContent ? toCurrencyString(val, '') : ''
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
